var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"job_form",staticClass:"base-form",on:{"submit":function($event){$event.preventDefault();return _vm.submit_form.apply(null, arguments)}},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label",class:{
            'text-left': _vm.get_site_direction === _vm.SiteDirections.LTR,
            'text-right': _vm.get_site_direction === _vm.SiteDirections.RTL
          }},[_vm._v(" "+_vm._s(_vm.$t("recruiter.post-job.manual.job-title-label"))+"* ")]),_c('AutoCompleteCustomJobSearch',{attrs:{"placeholder":_vm.$t('recruiter.post-job.manual.job-title-placeholder'),"is_standardize":true,"limit":"35","id_return_required":true},on:{"search_job":_vm.search_job}})],1)])],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label",class:{
            'text-left': _vm.get_site_direction === _vm.SiteDirections.LTR,
            'text-right': _vm.get_site_direction === _vm.SiteDirections.RTL
          }},[_vm._v(" "+_vm._s(_vm.$t("recruiter.post-job.manual.industry-label"))+"* ")]),_c('v-autocomplete',{staticClass:"form-field-input",attrs:{"items":_vm.unique_sectors,"item-text":"sector","item-value":"sector","loading":_vm.common_loading,"placeholder":_vm.$t('recruiter.post-job.manual.industry-placeholder'),"rules":_vm.job_industry_rules,"solo":""},model:{value:(_vm.job_industry),callback:function ($$v) {_vm.job_industry=$$v},expression:"job_industry"}})],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label",class:{
            'text-left': _vm.get_site_direction === _vm.SiteDirections.LTR,
            'text-right': _vm.get_site_direction === _vm.SiteDirections.RTL
          }},[_vm._v(" "+_vm._s(_vm.$t("recruiter.post-job.manual.job-type-label"))+"* ")]),_c('v-select',{staticClass:"form-field-input",attrs:{"items":_vm.job_types,"placeholder":_vm.$t('recruiter.post-job.manual.job-type-placeholder'),"type":"text","rules":_vm.job_type_rules,"solo":""},model:{value:(_vm.job_type),callback:function ($$v) {_vm.job_type=$$v},expression:"job_type"}})],1)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label",class:{
            'text-left': _vm.get_site_direction === _vm.SiteDirections.LTR,
            'text-right': _vm.get_site_direction === _vm.SiteDirections.RTL
          }},[_vm._v(" "+_vm._s(_vm.$t("recruiter.post-job.manual.experience-label"))+" ")]),_c('v-text-field',{staticClass:"form-field-input",attrs:{"placeholder":_vm.$t('recruiter.post-job.manual.experience-placeholder'),"type":"text","rules":_vm.job_experience_rules,"solo":""},model:{value:(_vm.job_experience),callback:function ($$v) {_vm.job_experience=$$v},expression:"job_experience"}})],1)])],1),_c('v-row',{staticClass:"mb-10"},[_c('v-col',[_c('div',{staticClass:"form-field"},[_c('label',{staticClass:"base-body-text form-field-label",class:{
            'text-left': _vm.get_site_direction === _vm.SiteDirections.LTR,
            'text-right': _vm.get_site_direction === _vm.SiteDirections.RTL
          }},[_vm._v(" "+_vm._s(_vm.$t("recruiter.post-job.manual.description-label"))+"* ")]),_c('v-textarea',{staticClass:"form-field-input",attrs:{"loading":_vm.common_loading,"auto-grow":"","rows":"12","placeholder":_vm.$t('recruiter.post-job.manual.description-placeholder'),"type":"text","rules":_vm.job_description_rules,"solo":"","hint":_vm.$t('recruiter.description-min-rule')},model:{value:(_vm.job_description),callback:function ($$v) {_vm.job_description=$$v},expression:"job_description"}})],1)])],1),(_vm.form && _vm.job_title)?_c('v-btn',{staticClass:"base-body-text base-submit-button",attrs:{"loading":_vm.post_job_loading,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("recruiter.done-btn-msg"))+" ")]):_c('v-btn',{staticClass:"base-body-text base-disable-button"},[_vm._v(" "+_vm._s(_vm.$t("recruiter.done-btn-msg"))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }